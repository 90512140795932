import { Inject, Injectable } from '@angular/core';
import { IAppointmentService } from './appointment-service.interface';
import { map, mergeMap, Observable } from 'rxjs';
import {
  IAppointment,
  IAppointmentHistory,
  IAppointmentSearchCondition,
} from '@main-data-access-interfaces';
import { HttpClient } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SearchResult } from '@main-data-access-models';

@Injectable()
export class AppointmentService implements IAppointmentService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    private readonly _httpClient: HttpClient
  ) {}

  //#endregion Constructor

  //#region Methods

  public getAppointmentsAsync(
    conditions: Partial<IAppointmentSearchCondition>
  ): Observable<SearchResult<IAppointment>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/appointments/search`;
        return this._httpClient
          .post<SearchResult<IAppointment>>(apiUrl, conditions)
          .pipe(
            map((result: SearchResult<IAppointment>) => {
              // TODO: need to change format datetime
              const updatedRecords = result.records.map((record) => ({
                ...record,
                startTime: record.startTime
                  ? record.startTime.replace('Z', '')
                  : record.startTime,
              }));

              return {
                ...result,
                records: updatedRecords,
              };
            })
          );
      })
    );
  }

  public getByIdAsync(id: string): Observable<IAppointment> {
    return this.getAppointmentsAsync({ id, limit: 1, page: 1 }).pipe(
      map((data) => data.records.find((x) => x.id === id) as IAppointment)
    );
  }

  public getHistoriesByAppointmentIdAsync(
    appointmentId: string
  ): Observable<IAppointmentHistory[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/appointments/${appointmentId}/histories`;
        return this._httpClient.get<IAppointmentHistory[]>(apiUrl);
      })
    );
  }

  //#endregion Methods
}
