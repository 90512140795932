import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { IPatientMedication } from '@main-data-access-interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import { SearchResult } from '@main-data-access-models';
import { IMedicationsService } from './medications-service.interface';

@Injectable()
export class MedicationsService implements IMedicationsService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getByMrnAsync(
    condition: { page: number; size: number },
    mrn: string
  ): Observable<SearchResult<IPatientMedication>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        let httpParams: HttpParams = new HttpParams().append(
          'page',
          condition.page
        );
        httpParams = httpParams.append('size', condition.size);

        return this._httpClient
          .get<SearchResult<IPatientMedication>>(
            `${baseUrl}/patients/${mrn}/medication`,
            {
              params: httpParams,
            }
          )
          .pipe(
            map((result: SearchResult<IPatientMedication>) => {
              // TODO: need to change format datetime
              const updatedRecords = result.records.map((record) => ({
                ...record,
                dispensedDate: record.dispensedDate
                  ? record.dispensedDate.replace('Z', '')
                  : record.dispensedDate,
              }));

              return {
                ...result,
                records: updatedRecords,
              };
            })
          );
      })
    );
  }

  //#endregion Methods
}
