import { Route } from '@angular/router';
import {
  AuthenticatedUserGuard,
  NotUnderMaintenanceGuard,
  UnauthenticatedUserGuard,
} from '@main-logics-guards';

export const routes: Route[] = [
  {
    path: 'authentication',
    canActivate: [UnauthenticatedUserGuard, NotUnderMaintenanceGuard],
    loadComponent: () =>
      import('./modules').then((m) => m.AuthenticationComponent),
    loadChildren: () => import('./modules').then((m) => m.authenticationRoutes),
  },
  {
    path: '',
    canActivate: [AuthenticatedUserGuard, NotUnderMaintenanceGuard],
    loadComponent: () => import('./modules').then((m) => m.PortalComponent),
    loadChildren: () => import('./modules').then((m) => m.portalRoutes),
  },
  {
    path: 'error',
    loadComponent: () => import('./modules').then((m) => m.ErrorComponent),
    loadChildren: () => import('./modules').then((m) => m.errorRoutes),
  },
];
