import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  APPOINTMENT_SERVICE,
  IAppointmentService,
} from '@main-data-access-services';
import { IPatientAppointmentsComponentState } from './patient-appointments.state';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  finalize,
  pipe,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Router } from '@angular/router';
import { RoutePaths } from '@main-data-access-enums';
import { IAppointment } from '@main-data-access-interfaces';
import { Store } from '@ngrx/store';
import { IPatientDetailState } from '../patient-detail.state';
import { PatientDetailSelectors } from '../patient-detail.selectors';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class PatientAppointmentsComponentStore extends ComponentStore<IPatientAppointmentsComponentState> {
  //#region Properties

  public readonly patient$ = this._store.select(PatientDetailSelectors.patient);

  protected readonly _refresh$ = new BehaviorSubject<Date>(new Date());

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(APPOINTMENT_SERVICE)
    protected readonly _appointmentService: IAppointmentService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService,
    protected readonly _store: Store<IPatientDetailState>,
    protected readonly _router: Router
  ) {
    super({
      searchResult: {
        records: [],
        total: 0,
      },
      condition: {
        limit: 10,
        page: 1,
        orderBy: {
          direction: 'DESC',
          field: 'startTime',
        },
      },
      loading: false,
    });
  }

  //#endregion Constructor

  //#region Lifecycle

  //#endregion

  //#region Methods

  public readonly searchAppointments = this.effect<never>(
    pipe(
      switchMap(() =>
        combineLatest([
          this.patient$.pipe(take(1)),
          this.select((state) => state.condition),
          this._refresh$,
        ])
      ),
      switchMap(([patient, search]) => {
        this.patchState({
          loading: true,
        });
        return this._appointmentService
          .getAppointmentsAsync({
            ...search,
            patientId: patient!.mrn,
          })
          .pipe(
            tap((searchResult) => {
              this.patchState({
                searchResult: searchResult,
              });
            }),
            finalize(() => {
              this.patchState({
                loading: false,
              });
            }),
            catchError(() => {
              this._alertService.error(
                'Error',
                `Failed to get patient's appointments`
              );
              return EMPTY;
            })
          );
      })
    )
  );

  public readonly viewHistory = this.effect<IAppointment>(
    pipe(
      tap((item) =>
        this._router.navigate(['/', RoutePaths.APPOINTMENT, item.id])
      )
    )
  );

  public refresh(): void {
    this._refresh$.next(new Date());
  }

  //#endregion Methods
}
