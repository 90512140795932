import {
  ChangeDetectionStrategy,
  Component,
  importProvidersFrom,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  PreloadAllModules,
  Router,
  RouterModule,
} from '@angular/router';
import { routes } from './app.routes';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationConfig, BrowserModule } from '@angular/platform-browser';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { environment } from '@main-resources-environments';
import { TranslocoHttpLoader } from '@main-data-access-constants';
import {
  IRootState,
  PortalLayoutActions,
  RootStoreModule,
} from '@main-data-access-stores';
import {
  ApiEndpointResolverModule,
  BreadcrumbResolverModule,
  ScreenResolverModule,
} from '@main-data-access-resolvers';
import { AccessTokenInterceptorModule } from '@main-logics-interceptors';
import { AppSettingsFactoryModule } from '@main-logics-factories';
import {
  REQUIREMENT_HANDLER,
  RequirementSentinelModule,
  SmartNavigatorModule,
  SpinnerContainerModule,
  VALIDATION_SUMMARIZER_SERVICE,
  ValidationSummarizerModule,
  WindowAccessorModule,
} from '@ui-tool/core';
import {
  AbleToViewAppointmentHistoriesRequirementHandler,
  AbleToViewAppointmentsRequirementHandler,
  AbleToViewDashboardRequirementHandler,
  AbleToViewDiagnosesRequirementHandler,
  AbleToViewPatientDetailsRequirementHandler,
  AbleToViewPatientsRequirementHandler,
  AbleToViewUsersRequirementHandler,
  AuthenticationServiceModule,
  BasicValidationSummarizerService,
  FeatureSentinelServiceModule,
} from '@main-data-access-services';
import { LocalForageModule } from 'ngx-localforage';
import {
  AuthenticatedUserGuard,
  DashboardGuard,
  NotUnderMaintenanceGuard,
  UnauthenticatedUserGuard,
  UsersGuard,
} from '@main-logics-guards';
import { NgxEchartsModule } from 'ngx-echarts';
import { autoDestroy, DSAlertModule, followDestroy } from '@design-system';
import { tap } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  selector: 'm-ocloud',
  imports: [RouterModule],
  template: ` <router-outlet></router-outlet> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@autoDestroy()
export class AppComponent {
  public static get bootstrap(): ApplicationConfig {
    return {
      providers: [
        importProvidersFrom(
          BrowserModule,
          BrowserAnimationsModule,
          LocalForageModule.forRoot(),
          HttpClientModule,
          TranslocoModule,
          BreadcrumbResolverModule,
          AccessTokenInterceptorModule,
          RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            enableTracing: false,
          }),
          DSAlertModule,
          RootStoreModule,
          AppSettingsFactoryModule,
          ApiEndpointResolverModule,
          WindowAccessorModule,
          AuthenticationServiceModule,
          SmartNavigatorModule.forRoot(),
          SpinnerContainerModule.forRoot(),
          ScreenResolverModule,
          FeatureSentinelServiceModule,
          ValidationSummarizerModule.forRoot({
            validatorProvider: {
              provide: VALIDATION_SUMMARIZER_SERVICE,
              useClass: BasicValidationSummarizerService,
            },
          }),

          NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
          }),
          RequirementSentinelModule.forRoot(),
          RequirementSentinelModule.withRequirementHandlers([
            {
              provide: REQUIREMENT_HANDLER,
              useClass: AbleToViewPatientDetailsRequirementHandler,
              multi: true,
            },
            {
              provide: REQUIREMENT_HANDLER,
              useClass: AbleToViewAppointmentsRequirementHandler,
              multi: true,
            },
            {
              provide: REQUIREMENT_HANDLER,
              useClass: AbleToViewDiagnosesRequirementHandler,
              multi: true,
            },
            {
              provide: REQUIREMENT_HANDLER,
              useClass: AbleToViewAppointmentHistoriesRequirementHandler,
              multi: true,
            },
            {
              provide: REQUIREMENT_HANDLER,
              useClass: AbleToViewUsersRequirementHandler,
              multi: true,
            },
            {
              provide: REQUIREMENT_HANDLER,
              useClass: AbleToViewDashboardRequirementHandler,
              multi: true,
            },
            {
              provide: REQUIREMENT_HANDLER,
              useClass: AbleToViewPatientsRequirementHandler,
              multi: true,
            },
          ])
        ),
        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            availableLangs: ['en'],
            defaultLang: 'en',
            reRenderOnLangChange: true,
            prodMode: environment.production,
            fallbackLang: 'en',
          }),
        },
        {
          provide: TRANSLOCO_LOADER,
          useClass: TranslocoHttpLoader,
        },
        AuthenticatedUserGuard,
        UnauthenticatedUserGuard,
        UsersGuard,
        DashboardGuard,
        NotUnderMaintenanceGuard,
      ],
    };
  }

  public constructor(
    protected readonly _router: Router,
    protected readonly _store: Store<IRootState>
  ) {
    this._router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationStart) {
            this._store.dispatch(PortalLayoutActions.showLoading());
          }

          if (event instanceof NavigationEnd) {
            const timeout = setTimeout(() => {
              this._store.dispatch(PortalLayoutActions.hideLoading());
              clearTimeout(timeout);
            }, 1000);
          }
        }),
        followDestroy(this)
      )
      .subscribe();
  }
}
