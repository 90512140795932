import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  DSModalFooterDirections,
  DSModalSizes,
  DSModalStatuses,
} from './enums';
import { DSButtonAppearances } from '../../common/button';
import { DSModalBodyDirective, DSModalFooterDirective } from './directives';

@Component({
  selector: 'm-ocloud-ds-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSModalComponent {
  @ContentChild(DSModalBodyDirective)
  public readonly bodyTemplate: DSModalBodyDirective | null = null;

  @ContentChild(DSModalFooterDirective)
  public readonly footerTemplate: DSModalFooterDirective | null = null;

  @Output()
  public readonly confirmButtonClick = new EventEmitter();

  @Output()
  public readonly cancelButtonClick = new EventEmitter();

  @Output()
  public readonly closeButtonClick = new EventEmitter();

  @Input()
  public hiddenCloseButton = false;

  @Input()
  public confirmButtonLabel = 'Confirm';

  @Input()
  public confirmButtonDisabled = false;

  @Input()
  public confirmButtonAppearance:
    | DSButtonAppearances
    | `${DSButtonAppearances}` = DSButtonAppearances.PRIMARY;

  @Input()
  public hiddenConfirmButton = false;

  @Input()
  public cancelButtonLabel = 'Cancel';

  @Input()
  public cancelButtonDisabled = false;

  @Input()
  public cancelButtonAppearance:
    | DSButtonAppearances
    | `${DSButtonAppearances}` = DSButtonAppearances.SECONDARY_GRAY;

  @Input()
  public hiddenCancelButton = false;

  @Input()
  public status: DSModalStatuses | `${DSModalStatuses}` = DSModalStatuses.FORM;

  @Input()
  public size: DSModalSizes | `${DSModalSizes}` = DSModalSizes.XS;

  @Input()
  public footerDirections:
    | DSModalFooterDirections
    | `${DSModalFooterDirections}` = DSModalFooterDirections.ROW;

  @Input()
  public title = '';

  @Input()
  public description = '';

  public get statusIconLink(): string {
    return `design-system/assets/svgs/modal/${this.status}.svg`;
  }

  public disableConfirmButton(): void {
    this.confirmButtonDisabled = true;
  }
}
