import { CanActivate, UrlTree } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import {
  ISmartNavigatorService,
  NavigateToScreenRequest,
  SMART_NAVIGATOR_SERVICE,
} from '@ui-tool/core';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
} from '@main-data-access-services';
import { ScreenCodes } from '@main-data-access-enums';

@Injectable()
export class NotUnderMaintenanceGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService
  ) {}

  //#endregion

  //#region Methods

  public canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((appSettings) => {
        if (
          appSettings &&
          appSettings.underMaintenance &&
          [1, '1', 'true', true].indexOf(appSettings.underMaintenance) !== -1
        ) {
          return this._navigationService.buildUrlTree(
            ScreenCodes.UNDER_MAINTENANCE
          );
        }

        return true;
      }),
      catchError(() => {
        return this._navigationService.navigateToScreenAsync(
          new NavigateToScreenRequest<boolean>(ScreenCodes.UNDER_MAINTENANCE)
        );
      })
    );
  }

  //#endregion
}
